import type { ReactNode } from "react";
import React from "react";
import ActionLink from "../../../../components/theming/ActionLink";
import styles from "../../../../components/Layout/Layout.module.css";
import {
  IntelligentTestingAnnouncementClick,
  OPEN_IN_NEW_TAB,
} from "../../../../Constants";

export interface AnnouncementData {
  content: ReactNode;
}

const defaultItems: AnnouncementData[] = [
  {
    content: (
      <div>
        <b>New Feature:</b>{" "}
        <ActionLink
          className={styles.link}
          eventName={IntelligentTestingAnnouncementClick}
          onClick={() =>
            window.open(
              "https://signadot.com/docs/guides/use-cases/run-automated-tests",
              OPEN_IN_NEW_TAB
            )
          }
        >
          Run Automated Tests on PRs
        </ActionLink>{" "}
        in your Kubernetes cluster
      </div>
    ),
  },
];

export default defaultItems;
