import { useEffect, useState } from "react";
import posthog from "posthog-js";
import { useAuth } from "../contexts/AuthContext";

const useFeatureFlag = (featureName: string) => {
  const [featureIsAvailable, setFeatureIsAvailable] = useState(false);
  const { state } = useAuth();
  const { org } = state;

  useEffect(() => {
    posthog.reloadFeatureFlags();

    posthog.setGroupPropertiesForFlags({ org: { name: org?.name } });

    if (posthog.isFeatureEnabled(featureName)) {
      setFeatureIsAvailable(true);
    }
  }, []);

  return featureIsAvailable;
};

export default useFeatureFlag;
