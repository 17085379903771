import { useQuery } from "react-query";
import type { TestSpec } from "../@types/sd/testspec";

export const LIST_TEST_SPECS_API_NAME = "api:test-specs";

export const useGetTestSpecs = (options = {}) =>
  useQuery<TestSpec[]>(
    LIST_TEST_SPECS_API_NAME,
    async () => [
      {
        name: "my-test-1",
        spec: {
          kind: "test",
          type: "api-autodiff",
          triggers: {
            services: ["apiserver", "web"],
          },
        },
      },
      {
        name: "my-test-2",
        spec: {
          kind: "test",
          type: "api-autodiff",
          triggers: {
            services: ["apiserver"],
          },
        },
      },
    ],
    {
      staleTime: Infinity, // The data is considered fresh indefinitely
      ...options,
    }
  );
