import type { TestSpec } from "../../../@types/sd/testspec";

// eslint-disable-next-line import/prefer-default-export
export const filterTestSpecs = (
  items: TestSpec[],
  filterText: string
): TestSpec[] =>
  items.filter((item) =>
    filterText
      .toLowerCase()
      .trim()
      .split(" ")
      .some(
        (token) =>
          item.name.toLowerCase().includes(token) ||
          (item.spec.triggers?.services || []).some((service) =>
            service.toLowerCase().includes(token)
          )
      )
  );
