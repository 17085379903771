import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Tag } from "@blueprintjs/core";
import { TbServerBolt } from "react-icons/tb";
import { SdHeading1 } from "../../../components/theming/SdHeading";
import styles from "./TestSpecs.module.css";
import { filterTestSpecs } from "./utils";
import SdInput from "../../../components/theming/SdInput";
import SdButton from "../../../components/theming/SdButton";
import CreateFirst from "../../../components/CreateFirst";
import { useGetTestSpecs } from "../../../api/TestSpecApi";

const TestSpecs: React.FC = () => {
  const [filterText, setFilterText] = React.useState<string>("");
  const { isSuccess, data } = useGetTestSpecs();
  const filteredList = React.useMemo(
    () => filterTestSpecs(data || [], filterText),
    [data, isSuccess, filterText]
  );
  const navigate = useNavigate();

  if (!isSuccess) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SdHeading1 small lightBackground>
          Test Specs
        </SdHeading1>
      </div>
      {data.length > 0 ? (
        <>
          <div>
            <SdInput
              name="search-field"
              type="text"
              placeholder="Type to search ..."
              className="bp4-input bp4-fill bp4-medium mb-4 mt-10"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <div className={styles.catalog}>
            {filteredList.map((entry) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <Card
                key={entry.name}
                className={styles.entry}
                onClick={() => navigate(`/testing/test-specs/${entry.name}`)}
              >
                <>
                  <div className={styles.title}>{entry.name}</div>
                  <div className={styles.testType}>type: {entry.spec.type}</div>
                  {entry.spec.triggers?.services?.length ? (
                    <div className={styles.tags}>
                      <div className={styles.tagsTitle}>Triggers:</div>
                      <div className={styles.tagsList}>
                        {entry.spec.triggers.services.map((key) => (
                          <Tag key={key} className={styles.tag}>
                            service: {key}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </>
              </Card>
            ))}
          </div>
          <SdButton
            onClick={() => {
              navigate(`/testing/test-specs/editor`);
            }}
            icon="plus"
          >
            Create Test Spec
          </SdButton>
        </>
      ) : (
        <CreateFirst
          icon={<TbServerBolt size={80} />}
          message="No Test Specs found"
          buttonLabel="Create Test Spec"
          onClick={() => navigate("/testing/test-specs/editor")}
        />
      )}
    </div>
  );
};

export default TestSpecs;
