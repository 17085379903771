import React from "react";
import { useNavigate } from "react-router-dom";
import MultiTabsLayout from "../../../../components/MultiTabsLayout/MultiTabsLayout";
import {
  TestSpecEditorTabCode,
  TestSpecEditorTabSpecification,
} from "../../../../Constants";
import SpecPane from "./tabs/SpecPane";
import CodePane from "./tabs/CodePane";

const TestSpecEditor: React.FC = () => {
  const navigate = useNavigate();
  const [title, setTitle] = React.useState("Untitled");
  const tabs = [
    {
      label: "Specification",
      slug: "specification",
      eventName: TestSpecEditorTabSpecification,
      content: <SpecPane />,
    },
    {
      label: "Code",
      slug: "code",
      eventName: TestSpecEditorTabCode,
      content: <CodePane />,
    },
  ];
  const breadcrumbs = [
    {
      text: "Testing :: Test Specs",
      onClick: () => navigate("/testing/test-specs"),
    },
    { text: "Editor" },
  ];
  return (
    <MultiTabsLayout title={title} tabs={tabs} breadcrumbs={breadcrumbs} />
  );
};

export default TestSpecEditor;
