import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from "./EmptyExecutions.module.css";
import { useGetRunnerGroups } from "../../../../../api/RunnerGroupApi";
import CreateFirst from "../../../../../components/CreateFirst";

const ContainerMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyExecutions = () => {
  const { isSuccess, data } = useGetRunnerGroups();
  const navigate = useNavigate();

  if (!isSuccess) return null;

  // TODO: If we don't return special JRGs and user only use those features, will see
  // the error page
  return (
    <div className={styles.container}>
      {data.length === 0 ? (
        <CreateFirst
          icon="lab-test"
          message={
            <ContainerMessage>
              <p>
                No Job Runner Group Found. Before submitting any Job, you must
                set up a Job Runner Group. See the
                <a href="https://www.signadot.com/docs/reference/job-runner-groups/spec">
                  {" "}
                  documentation
                </a>{" "}
                for details.
              </p>
            </ContainerMessage>
          }
          buttonLabel="Create Job Runner Group"
          onClick={() => navigate("/testing/job-runner-groups/editor")}
        />
      ) : (
        <CreateFirst
          icon="lab-test"
          message={
            <ContainerMessage>
              <p>
                No Job runs found. You can start submitting Jobs with the
                Signadot CLI. Refer to the
                <a
                  href="https://www.signadot.com/docs/reference/jobs/spec"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  documentation
                </a>{" "}
                for details.
              </p>
            </ContainerMessage>
          }
        />
      )}
    </div>
  );
};

export default EmptyExecutions;
